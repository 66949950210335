/* eslint-disable react/no-unescaped-entities */

'use client';

// @mui
import { useTheme } from '@mui/material/styles';

import { useEffect } from 'react';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// theme
import { paper } from 'src/theme/css';
//
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import SignupForm from 'src/sections/auth/signup/signup-form';
import ContactForm from 'src/sections/contact/contact-form';
import { fireContactOpen, fireStart } from 'src/utils/tracking';
import { Chip } from '@mui/material';
import { useSignupContext } from './context/signup-context';
import Logo from '../logo';
//

// ----------------------------------------------------------------------

export default function SignupPopup() {
  const theme = useTheme();

  const signup = useSignupContext();

  useEffect(() => {
    if (signup.open) {
      if (signup.mode === 'contact') {
        fireContactOpen();
        return;
      }
      fireStart();
    }
  }, [signup]);

  const renderContact = (
    <Stack sx={{ pt: 3.5, pb: 2, px: { xs: 2, md: 4 } }}>
      <Typography variant="h3" sx={{ lineHeight: 1.15, textAlign: 'center', mb: { xs: 1, md: 2 } }}>
        Perfect. Let's get you scheduled!
      </Typography>
      <Grid xs={12} md={6} sx={{ pt: 2, pb: 1, alignItems: 'center', display: 'flex' }}>
        <ContactForm />
      </Grid>
    </Stack>
  );

  const renderSignup = (
    <Grid container sx={{ py: { xs: 0, md: 1 }, px: { xs: 1, md: 2 } }}>
      <Grid xs={12} md={6} sx={{ p: 2 }}>
        <Logo />
        <Typography variant="h2" sx={{ lineHeight: 1.15 }} gutterBottom>
          Start{' '}
          <Box component="span" sx={{ color: 'accent.main' }}>
            NOW
          </Box>
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Get better results from your Ad Spend with the same simple tracking setup.{' '}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{ p: 2, pb: 1, alignItems: 'center', display: 'flex', flexDirection: 'column' }}
      >
        <SignupForm mode={signup.mode} />
      </Grid>
      {signup.onboardingSource && (
        <Box sx={{ mt: 0, width: '100%', pl: 2, pr: 2, pb: 1.5 }}>
          <Chip
            variant="outlined"
            label={`Partner signup for ${signup.onboardingSource.title}.`}
            sx={{ fontWeight: 600, width: '100%', borderColor: 'text.disabled', borderRadius: 4 }}
          />
        </Box>
      )}
    </Grid>
  );

  return (
    <Dialog
      open={!!signup.open}
      onClose={signup.closeable ? signup.onClose : null}
      slotProps={{
        backdrop: { invisible: false, sx: { bgcolor: 'rgba(22, 28, 36, 0.5)' } },
      }}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          m: 0,
          width: { xs: 350, md: 700 },
          maxWidth: { xs: '100%', md: 700 },
        },
      }}
    >
      {signup.mode === 'contact' ? renderContact : renderSignup}
    </Dialog>
  );
}
