'use client';

import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
// routes
// config
// hooks
// auth
// components
import Stack from '@mui/material/Stack';
import RegisterForm from './components/register';
import LoginForm from './components/login';

// ----------------------------------------------------------------------

export default function SignupForm({ mode = 'register' }) {
  const [currentMode, setCurrentMode] = useState(mode);

  return (
    <Stack sx={{ width: '100%' }}>
      {currentMode === 'login' && <LoginForm onModeChanged={setCurrentMode} />}
      {currentMode === 'register' && <RegisterForm onModeChanged={setCurrentMode} />}
    </Stack>
  );
}

SignupForm.propTypes = {
  mode: PropTypes.string,
};
