'use client';

import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { camelCaseObject } from 'src/utils/change-case';
import { getCookie } from 'cookies-next';
import { fireContactInfo, fireRegistered } from 'src/utils/tracking';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);

        const { user } = response.data;
        fireContactInfo(user);

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {

    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);
    handleLoginResponse(response);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleLoginResponse = useCallback((response) => {
    if (!response) {
      return;
    }
    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, name, onboardingSource) => {
    const byAffiliate = getCookie('by_affiliate');

    const data = {
      email,
      password,
      name,
      referral_code: byAffiliate,
      onboarding_source_type: onboardingSource?.type,
      onboarding_source_alias: onboardingSource?.alias,
    };
    fireRegistered('email');

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);


    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // const registerViaInvite = useCallback(async (email, password, name, token) => {
  //   const byAffiliate = getCookie('by_affiliate');
  //
  //   const data = {
  //     email,
  //     password,
  //     name,
  //     token,
  //     referral_code: byAffiliate
  //   };
  //
  //   const response = await axios.post(endpoints.auth.registerViaInvite, data);
  //
  //   const {accessToken, user} = response.data;
  //
  //   localStorage.setItem(STORAGE_KEY, accessToken);
  //
  //   fireRegistered()
  //
  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user,
  //     },
  //   });
  // }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
    window.location.href = '/'; // To reload the page and clear React variables
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
      () => ({
        user: camelCaseObject(state.user),
        method: 'jwt',
        loading: status === 'loading',
        authenticated: status === 'authenticated',
        unauthenticated: status === 'unauthenticated',
        //
        login,
        handleLoginResponse,
        register,
        logout,
        refreshUser: initialize,
      }),
      [login, handleLoginResponse, logout, register, state.user, status, initialize],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
