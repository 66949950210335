'use client';

import { useState } from 'react';
// @mui
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
// routes
// config
// auth
// components
import PropTypes from 'prop-types';
import EmailRegisterForm from './email-register';
import GoogleLogin from './google-login';
import { IS_CLIENT_SERVER } from '../../../../config-global';

// ----------------------------------------------------------------------

export default function RegisterForm({ open = false, onModeChanged }) {
  const [isEmailRegisteringOpen, setIsEmailRegisteringOpen] = useState(open);

  return (
    <Stack spacing={3} sx={{pb: isEmailRegisteringOpen ? 0 : 4}}>
      <Stack spacing={0.5}>
        <Typography variant='h4'>Create a new account</Typography>
        <Stack direction='row' spacing={0.5}>
          <Typography variant='body2'> Already have an account? </Typography>

          <Link variant='subtitle2' onClick={() => onModeChanged('login')}  sx={{ cursor: 'pointer', color: 'accent.main' }}>
            Sign in
          </Link>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        {!isEmailRegisteringOpen && !IS_CLIENT_SERVER && <GoogleLogin />}
        <EmailRegisterForm isOpen={isEmailRegisteringOpen} onIsOpenChanged={setIsEmailRegisteringOpen} />
      </Stack>
    </Stack>
  );
}

RegisterForm.propTypes = {
  open: PropTypes.bool,
  onModeChanged: PropTypes.func
};

