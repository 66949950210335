import mapKeys from 'lodash/mapKeys';
import lodashCamelCase from 'lodash/camelCase';

export function paramCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}

export function camelCase(str) {
  return lodashCamelCase(str);
}

export function camelCaseObject(obj) {
  return mapKeys(obj, (v, k) => camelCase(k));
}
